
<template>
  <div>
    <el-table stripe :data="list" style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);">
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column align="center" label="订单编号" min-width="150">
        <template slot-scope="scope">
          <!-- <div class="goto-detail" @click="editDetail(scope.row.vouchersId, 1)">{{scope.row.vouchersTitle}}</div> -->
          <span class="table-text" @click="showDetail(scope.row)">{{scope.row.orderNo || '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column align="left" prop="goodsName" label="商品名称" min-width="160" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="orderAmount" label="订单金额" width="110"></el-table-column>
      <el-table-column align="center" prop="payAmount" label="付款金额" width="110"></el-table-column>
      <el-table-column label="下单人" prop="userName" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column label="下单号码" prop="userPhone" show-overflow-tooltip align="center"></el-table-column>
      <!-- <el-table-column label="下单人号码" prop="userId" show-overflow-tooltip align="center"></el-table-column> -->
      <el-table-column label="物流单号" prop="trackingNo" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span>{{scope.row.trackingNo || '--'}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="price" align="center" label="价格" width="120"></el-table-column> -->
      <!-- <el-table-column prop="integralPrice" align="center" label="积分价格" width="120"></el-table-column> -->
      <!-- <el-table-column prop="createName" align="center" label="创建人"></el-table-column> -->
      <el-table-column prop="createTime" align="center" label="创建时间" width="150"></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button icon="el-icon-view" style="color:#333" type="text" @click="showDetail(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="margin-top:10px;" layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>
    <orderDetail v-if="show" :show.sync="show" :rowItem="rowItem"></orderDetail>
  </div>
</template>
<script>
import {
  getVouchersList,
  delVouchers,
  getRegionByRegionCode,
  isDisable,
  getShoppingOrderPage
} from "@/api/setting";
import orderDetail from "./components/orderDetail.vue";


export default {
  name: "cardCouponCoupons",
  components: {
    orderDetail
  },
  data() {
    return {
      editId: "",
      editType: "", // 编辑状态 1是详情，2是编辑,''是添加

      province: "",
      city: "",
      search: "", //搜索

      list: [],
      total: 0, //分页总页数
      pageNum: 1, //分页页数
      pageSize: 10, //每页显示多少
      couponsType: "", //页面类型，0：油券 1：洗保喷券
      dataTree: [],
      cityTree: [],
      copyItem: {},
      form: {
        pageNum: 1, //分页页数
        pageSize: 10, //每页显示多少
        search: ''
      },
      show: false,
      rowItem: {}
    };
  },
  watch: {
    isAddDialog(n, o) {
      //关闭dialog后获取列表
      !n && this.getList();
    }
  },
  created() {
    this.getList();
  },
  methods: {
    showDetail(item) {
      this.show = true
      this.rowItem = item
    },
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getList();
    },
    getList() {
      getShoppingOrderPage(this.form).then(response => {
        if (response.code == 200) {
          this.total = response.data.total;
          this.list = response.data.records;
        } else {
          this.$message.error({
            title: "错误",
            message: response.msg,
            duration: 1500
          });
        }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.table-text {
  color: #409eff;
  cursor: pointer;
}
</style>