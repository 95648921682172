<template>
  <el-dialog title="订单详情" :visible.sync="show" width="400px" :before-close="handleClose">
    <el-form size="mini" label-width="100px">
      <el-form-item label="订单编号">
        {{item.orderNo || '--'}}
      </el-form-item>
      <el-form-item label="订单金额">
        {{item.orderAmount || '--'}}
      </el-form-item>
      <el-form-item label="付款金额">
        {{item.payAmount || '--'}}
      </el-form-item>
      <el-form-item label="下单人">
        {{item.userName}}
      </el-form-item>
      <el-form-item label="下单人电话">
        {{item.userPhone}}
      </el-form-item>
      <el-form-item label="物流单号">
        {{item.trackingNo || '--'}}
      </el-form-item>
      <el-form-item label="商品详情">
        <el-collapse v-model="activeNames">
          <el-collapse-item :title="item1.goodsName" v-for="(item1,index) in item.list" :name="index+1">
            <template slot="title">
              <el-tooltip class="item" effect="dark" :content="item1.goodsName" placement="top-start">
                <span class="brak">{{item1.goodsName}}</span>
              </el-tooltip>
            </template>
            <div>
              <div class="left">购买数量：{{item1.num}}</div>
              <div class="left">规格：{{item1.goodsSize}}{{item1.goodsUnit}}</div>
              <div class="left">单价：{{item1.price}}</div>
              <div class="left">金额：{{Number(item1.num*item1.price).toFixed(2)}}</div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form-item>

      <!-- <el-form-item label="总价">
        {{item.price}}
      </el-form-item>
      <el-form-item label="支付金额">
        {{item.payAmount}}
      </el-form-item>
      <el-form-item label="支付积分">
        {{item.payIntegral}}
      </el-form-item> -->
      <el-form-item label="下单时间">
        {{item.payTime}}
      </el-form-item>
      <el-form-item label="收货地址">
        {{item.shippingName}}
      </el-form-item>
      <!-- <el-form-item label="状态">
        {{stateList[item.isUsed]}}
      </el-form-item>
      <el-form-item label="使用时间">
        {{item.usedTime}}
      </el-form-item> -->
    </el-form>
    <div>
      <el-button @click="handleClose">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getShoppingOrderDetali } from "@/api/setting";
export default {
  name: 'DetailConsumption',
  props: ['show', 'rowItem'],
  data() {
    return {
      item: {},
      activeNames: []
    }

  },
  created() {
    this.getShoppingOrder()
  },
  methods: {
    getShoppingOrder() {
      getShoppingOrderDetali({ orderId: this.rowItem.id }).then(res => {
        console.log(res.data);
        this.item = res.data
      })
    },
    handleClose() {
      this.$emit('update:show', false)
    },
  }
}
</script>

<style lang="scss" scoped>
.brak {
  overflow: hidden;
  text-overflow: ellopsis;
  /* 弹性伸缩盒子模型显示 */
  display: -webkit-box;
  /* 限制在一个块元素显示的文本的行数 */
  -webkit-line-clamp: 1;
  /* 设置或检索伸缩盒对象的子元素的排列方式 */
  -webkit-box-orient: vertical;
}
.left {
  text-align: left;
}
</style>